<template>
  <div>
    <b-container>
      <b-row class="match-height">
        <b-col md="6" lg="4" xl="3">
          <!-- Add another business -->
          <b-card
            no-body
            :img-src="require('@/assets/images/bw/addanotherbusiness.jpg')"
            img-alt="Image"
            img-top
          >
            <b-card-body
              class="border-top p-1 d-flex flex-column justify-content-between"
            >
              <div>
                <b-card-title>Add Another Business</b-card-title>
                <b-card-text class="">
                  <p>
                    Bring another of your businesses to us for their waste
                    service, get free delivery on your new site and £15+VAT
                    credit on your existing account.
                  </p>
                </b-card-text>
              </div>
              <div class="d-flex w-100">
                <router-link
                  :to="{
                    path: '/contact',
                    query: {
                      subject: 'Additional',
                      leadSource: 'Portal-Additional'
                    }
                  }"
                  class="w-100"
                >
                  <b-button
                    class="mt-1 btn-sm d-block w-100"
                    style="
                      background-color: #6b9889 !important;
                      border-color: #6b9889 !important;
                      color: #fff !important;
                    "
                  >
                    Add Another Business
                  </b-button>
                </router-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Refer a friend -->
        <b-col md="6" lg="4" xl="3">
          <b-card
            no-body
            :img-src="require('@/assets/images/bw/referafriend.jpg')"
            img-alt="Image"
            img-top
          >
            <b-card-body
              class="border-top p-1 d-flex flex-column justify-content-between"
            >
              <div>
                <b-card-title>Refer A Friend</b-card-title>
                <b-card-text>
                  <p>
                    Refer a friend to us for their business waste services. If
                    they sign up to services with us, you get £15+VAT credit on
                    your account, and they get free delivery.
                  </p>
                </b-card-text>
              </div>
              <div class="d-flex w-100">
                <router-link
                  :to="{
                    path: '/contact',
                    query: {
                      subject: 'Referral',
                      leadSource: 'Portal-Referral'
                    }
                  }"
                  class="w-100"
                >
                  <b-button
                    class="mt-1 btn-sm d-block w-100"
                    style="
                      background-color: #6b9889 !important;
                      border-color: #6b9889 !important;
                      color: #fff !important;
                    "
                  >
                    Refer a Friend
                  </b-button>
                </router-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Utilibee -->
        <b-col md="6" lg="4" xl="3">
          <b-card
            no-body
            :img-src="require('@/assets/images/bw/utilibee.png')"
            img-alt="Image"
            img-top
          >
            <b-card-body class="border-top p-1 d-flex flex-column justify-content-between">
              <div>
                <b-card-title>Compare, Save & Switch Your Energy</b-card-title>
                <b-card-text>
                  <p>
                    Instantly compare business rates from over 20 energy suppliers
                    with Utilibee.
                  </p>
                </b-card-text>
              </div>
              <div class="d-flex w-100">
                <b-button
                  href="https://www.utilibee.co.uk/energy-comparison/business-energy-v2/?utmcampaign=BetterWasteMarketplace&utmsource=Referral&utmcontent=Marketplace&utmterm=none"
                  class="mt-1 btn-sm d-block w-100"
                  style="
                    background-color: #6b9889 !important;
                    border-color: #6b9889 !important;
                    color: #fff !important;
                  "
                  >Get a Free Quote</b-button
                >
              </div>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Websand -->
        <b-col md="6" lg="4" xl="3">
          <b-card
            no-body
            :img-src="require('@/assets/images/bw/websand.png')"
            img-alt="Image"
            img-top
          >
            <b-card-body class="border-top p-1 d-flex flex-column justify-content-between">
              <div>
                <b-card-title
                  >Turn your email marketing into a growth engine.</b-card-title
                >
                <b-card-text>
                  <p>
                    Websand makes it easy to bring your customer journey to life
                    and turn your signups into profitable customer relationships.
                    Sign up today and start sending targeted, personalised email
                    marketing in minutes.
                  </p>
                </b-card-text>
              </div>
              <b-button
                  href="https://www.websand.co.uk/quotexperts-email-marketing/"
                  class="mt-1 btn-sm d-block w-100"
                  style="
                    background-color: #6b9889 !important;
                    border-color: #6b9889 !important;
                    color: #fff !important;
                  "
                  >Get a Free Quote</b-button
                >
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
import {
  BRow,
  BButton,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BContainer,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BContainer,
  },
  data() {
    return {};
  },
};
</script>
